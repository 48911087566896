<template>
    <div class="demo14">
        <div class="top">
            <div :class="[{ activecss: active.id === item.id }, 'topitem']" @click="handleClickactive(item)"
                v-for="(item, index) in digitalList" :key="index">
                <img :src="item.digitalUrl" alt="" />
                <p>{{ item.digitalName }}</p>
            </div>
        </div>
        <div class="ipts">
            请输入文字： <el-input v-model="text" type="textarea" placeholder="输入文字" />
            请选择声音：<el-select v-model="valuesound" placeholder="请选择">
                <el-option v-for="item in soundList" :key="item.soundId" :label="item.soundName" :value="item.soundId">
                </el-option>
            </el-select>
            <el-button type="primary" @click="handleClicksendVideo">合成</el-button>
        </div>
        <div class="videolist">
            <div v-loading="item.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" class="videoitem" v-for="(item, index) in videolist"
                :key="index">
                <video controls :src="item.url"></video>
            </div>
        </div>
    </div>
</template>

<script>
import { videogetDigital, sendVideo, getVideoResult } from "@/api";
export default {
    data() {
        return {
            digitalList: [],
            active: {},
            text: "",
            soundList: [],
            valuesound: null,
            videolist: [],
            flag: true,
        };
    },
    mounted() {
        this.getvideolist();
    },
    methods: {
        getvideolist() {
            videogetDigital().then((res) => {
                console.log(res);
                this.digitalList = res.data.digitalList;
                this.soundList = res.data.soundList;
            });
        },
        handleClickactive(val) {
            this.active = val;
        },
        handleClicksendVideo() {
            console.log(JSON.stringify(this.active)=='{}');
            if (JSON.stringify(this.active) == '{}' || !this.text || !this.valuesound) {
                this.$message.warning('不能为空')
                return
            }
            if (!this.flag) {
                this.$message.warning('当前正在生成')
                return
            }
            this.flag = false
            sendVideo({
                figure: this.active.digitalId,
                speaker: this.valuesound,
                text: this.text,
            }).then((res) => {
                console.log(res);
                if (res.data) {
                    this.getstatus(res.data)
                    this.videolist.push({
                        requestId: res.data,
                        url: '',
                        loading: true
                    })
                    this.active.requestId = res.data
                    this.active.timers = setInterval(() => {
                        this.getstatus(res.data)
                    }, 5000);
                }
            });
        },
        getstatus(data) {
            getVideoResult(data).then(res => {
                if (res.data.status === '1') {
                    this.videolist.filter(item => {
                        if (item.requestId === res.data.requestId) {
                            item.url = res.data.url
                            item.loading = false
                            this.flag = true
                        }
                    })
                    this.digitalList.filter(item => {
                        if (item.requestId === res.data.requestId) {
                            clearInterval(item.timers)
                        }
                    })
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.demo14 {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .videolist {
        overflow: auto;
        flex: 1;

        .videoitem {
            width: 300px;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            video {
                width: 100%;
                height: 100%;
            }
        }
    }


    .ipts {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .el-textarea {
            width: 300px;
            margin-right: 20px;
        }

        .el-button {
            margin-left: 20px;
        }
    }

    .top {
        display: flex;
        height: 200px;
        overflow: auto;

        .topitem {
            width: 200px;
            height: 200px;
            background: #ccc;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            border: 1px solid rgba(0, 0, 0, 0);

            img {
                // width: 200px;
                height: 200px;
            }

            p {
                margin: 0;
                padding: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                background: rgba(0, 0, 0, 0.4);
                color: #fff;
            }
        }

        .activecss {
            border: 1px solid #000;

            p {
                background: #000;
            }
        }
    }
}
</style>